@import 'src/themes/vars.scss';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body, #root {
  height: 100%;
}

body {
  background-color: $white;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: #305d86;
  text-decoration: none;
}
