@import 'src/themes/vars.scss';

.news {
  height: 100%;
  margin: 15px;
  padding: $paddings;
  background-color: $white;
  overflow: hidden;
}

.table {
  max-height: 100%;
}

.actions {
  width: 100%;
  position: absolute;
  bottom: 16px;
  right: 24px;

  display: flex;
  justify-content: flex-end;
  padding: 10px 0;

  button {
    margin-left: 10px;
  }
}
