@import 'src/themes/vars.scss';

.sidebar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: $paddings;
}

.navs {
  width: 100%;

  li {
    width: 100%;
    display: inline-block;
  }
}

.link {
  display: inline-block;
  width: 100%;
  padding: 10px 0;
}

.activeLink {
  color: #1890ff;
}
